<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="answerRequestForm">
      <b-card body-class="p-1" class="border-secondary">
        <h4 class="text-center m-0">
          {{ formatPhone(item.phone) }}
        </h4>
      </b-card>
      <app-textarea-input v-model="msg" name="Mesaj" label="Mesaj:" placeholder="Mesaj giriniz..." length="5" rules="required" />
      <app-button @click="send" text="Yanıtla" block />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
export default {
  components: { ValidationObserver },
  data() {
    return {
      msg: "",
    };
  },
  computed: {
    item() {
      return this?.$store?.getters?.appSidebarItem?.data;
    },
    callback() {
      return this?.$store?.getters?.appSidebarItem?.callback;
    },
  },
  methods: {
    send() {
      this.$validate(this.$refs.answerRequestForm, () => {
        this.$axios
          .patch(
            `/customer-support/requests/send-answer-for-request/${this.item?._id}`,
            {
              message: this.msg,
            },
            { loading: "sidebar" }
          )
          .then(() => {
            this.$emitter.$emit("Notification", { variant: "success" });
            this.callback && this.callback();
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", message: err?.data?.message });
          });
      });
    },
    formatPhone(phone) {
      return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "0$1 $2 $3 $4");
    },
  },
};
</script>

<style lang="scss" scoped></style>
