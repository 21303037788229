<template>
  <validation-provider #default="{ errors }" :name="name" :vid="id" :rules="rules">
    <b-form-group :class="{ 'm-0': noGap }" :label-for="id" :label="label" :state="errors.length > 0 ? false : null">
      <vue-autosuggest
        @input="onInputChange"
        @selected="onSelected"
        v-model="val"
        :suggestions="filteredOptions"
        :limit="limit"
        :input-props="{ id: 'autosuggest__input', class: errors.length > 0 ? 'form-control is-invalid' : 'form-control', placeholder }"
      >
        <template slot-scope="{ suggestion }">
          <span class="my-suggestion-item">{{ suggestion.item }}</span>
        </template>
      </vue-autosuggest>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import { ValidationProvider } from "vee-validate";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    VueAutosuggest,
    ValidationProvider,
  },
  props: {
    noGap: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: uuidv4(),
    },
    datasuggest: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    rules: {
      type: String,
    },
  },
  data() {
    return {
      filteredOptions: [],
      limit: 10,
      val: "",
    };
  },
  methods: {
    onInputChange(text) {
      this.$emit("input", text);
      if (text === "" || text === undefined) {
        this.filteredOptions = [{ data: this.datasuggest }];
      }
      const filteredItems = this.datasuggest.filter((item) => item.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit);
      this.filteredOptions = [{ data: filteredItems }];
    },
    onSelected(data) {
      this.val = data?.item;
      this.$emit("input", this.val);
    },
  },
  mounted() {
    this.filteredOptions = [{ data: this.datasuggest }];
  },
  watch: {
    datasuggest: {
      deep: true,
      handler() {
        this.filteredOptions = [{ data: this.datasuggest }];
      },
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
