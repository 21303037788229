<template>
  <auto-suggest-input
    @input="$emit('input', { fullname: $event })"
    :datasuggest="callees"
    v-model="fullname"
    name="Görüşülen"
    label="* Görüşülen:"
    placeholder="Görüşülen..."
    rules="required"
  />
</template>

<script>
import autoSuggestInput from "@/views/app-components/input/autoSuggestInput.vue";
export default {
  props: ["contact_id"],
  components: { autoSuggestInput },
  data() {
    return {
      fullname: "",
      callees: [],
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    item() {
      return this.$store.getters.selectedParasutContact;
    },
  },
  methods: {
    getCallees() {
      (this.contact_id || this?.item?.contact_id || this?.selectedCompany?.parasut_contact_id) &&
        this.$axios
          .get("/finance/calls/callees/list", { params: { contact_id: this.contact_id ?? this?.item?.contact_id ?? this?.selectedCompany?.parasut_contact_id } })
          .then((result) => {
            this.callees = result?.data?.map((item) => item?.fullname);
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
  mounted() {
    this.getCallees();
  },
  watch: {
    contact_id() {
      this.getCallees();
    },
  },
};
</script>

<style></style>
