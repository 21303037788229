<template>
  <statistic-card title="Onay Bekleyenler" :count="count" :variant="count > 0 ? 'warning' : 'success'" icon="ClockIcon" icon-size="23" avatar-size="45" route="confirms" hover />
</template>

<script>
export default {
  data() {
    return {
      count: 0,
    };
  },
  mounted() {
    this.getCountOfPendingConfirms();
  },
  methods: {
    getCountOfPendingConfirms() {
      this.$axios
        .get("/confirms/pending/count")
        .then((result) => {
          this.count = result.data?.count || 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
