<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="updateRequestStatusForm">
      <b-card body-class="p-1" class="border-secondary">
        <h4 class="text-center m-0">
          {{ formatPhone(item.phone) }}
        </h4>
      </b-card>
      <app-select-input v-model="status" name="Durum" label="* Durum:" :options="options" placeholder="Durum Seçiniz..." rules="required" select_label="label" />
      <app-textarea-input name="Not" label="Not:" placeholder="Not..." length="5" />
      <app-button @click="updateReq" text="Güncelle" block />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
export default {
  components: { ValidationObserver },
  data() {
    return {
      status: "",
      options: [
        { key: "contacted", label: "Görüşüldü" },
        { key: "not_reached", label: "Ulaşılamadı" },
      ],
    };
  },
  computed: {
    item() {
      return this?.$store?.getters?.appSidebarItem?.data;
    },
    callback() {
      return this?.$store?.getters?.appSidebarItem?.callback;
    },
  },
  methods: {
    updateReq() {
      this.$validate(this.$refs.updateRequestStatusForm, () => {
        this.$axios
          .patch(
            `/customer-support/requests/update-request-status/${this.item?._id}`,
            {
              status: this.status,
            },
            { loading: "sidebar" }
          )
          .then(() => {
            this.$emitter.$emit("Notification", { variant: "success" });
            this.callback && this.callback();
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", message: err?.data?.message });
          });
      });
    },
    formatPhone(phone) {
      return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "0$1 $2 $3 $4");
    },
  },
};
</script>

<style lang="scss" scoped></style>
