<template>
  <app-overlay :loading="isLoading">
    <b-card class="border-secondary" body-class="p-25">
      <b-card-header class="p-1">
        <h4 class="m-0"><feather-icon class="mr-1" size="18" icon="PhoneOutgoingIcon" />Aranacaklar</h4>
        <span>
          {{ $FormattedDate(today) }}
        </span>
      </b-card-header>
      <hr class="m-0" />
      <b-alert class="m-1" variant="secondary" :show="showAlert">
        <div class="alert-body">
          <feather-icon size="18" class="mr-50" icon="InfoIcon" />
          <span> Bugüne ait kayıt bulunamadı. </span>
        </div>
      </b-alert>
      <b-list-group flush>
        <b-list-group-item v-for="(item, key) in items" :key="key">
          <div class="d-flex align-items-center justify-content-between gap-10">
            <div @click="updateItemShowDetails(item)" class="cursor-pointer" style="min-width: 50%">
              <h5>{{ item.callee.fullname }}</h5>
              <div class="text-truncate fs-12">{{ item.company.name }}</div>
            </div>
            <div class="d-flex justify-content-end flex-1 gap-10">
              <app-button @click="showEndOfForm(item)" text="Yeni Görüşme" />
              <feather-icon @click="updateItemShowDetails(item)" class="cursor-pointer" size="25" :icon="item.showDetails ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
            </div>
          </div>
          <b-card v-if="item.showDetails" class="border mt-1 mb-50" body-class="p-50">
            <div class="d-flex gap-10">
              <app-button @click="showContactDetails(item)" text="Müşteri Detayı" />
              <app-button @click="removeItem(item)" text="İşlemi Sonlandır" />
            </div>
            <app-detail-table v-if="item" :data="item" :fields="detailFields"> </app-detail-table>
          </b-card>
        </b-list-group-item>
      </b-list-group>
      <div v-if="total && total > 10">
        <hr class="mt-0" />
        <b-pagination @change="getData" v-model="currentPage" :total-rows="total" :per-page="10" align="center" size="sm" class="my-50" />
      </div>
    </b-card>
  </app-overlay>
</template>

<script>
import { DateTime } from "luxon";
import EndOfCallForm from "./EndOfCallForm.vue";
export default {
  data() {
    return {
      showAlert: false,
      isLoading: false,
      today: new Date().toISOString(),
      items: [],
      total: 0,
      currentPage: 1,
      detailFields: [
        { key: "status.label", label: "Sonuç", mdiIcon: "PhoneIncomingOutgoing" },
        { key: "description", label: "Açıklama", mdiIcon: "TextBoxOutline" },
        { key: "whenCalled", label: "Arama Tarihi", formatDate: true, icon: "CalendarIcon" },
        { key: "addedBy.fullname", label: "Kullanıcı", icon: "UserIcon" },
      ],
    };
  },
  methods: {
    getData(page) {
      const startOf = DateTime.now().setZone("Europe/Istanbul").startOf("day").toISO();
      const endOf = DateTime.now().setZone("Europe/Istanbul").endOf("day").toISO();
      this.isLoading = true;
      this.$axios
        .get("/finance/calls/list", {
          params: { page: page ?? this.currentPage, nextCall: JSON.stringify({ $gte: startOf, $lte: endOf }), archived: false },
        })
        .then((result) => {
          this.items = result?.data?.data?.map((item) => ({ ...item, showDetails: false })) ?? [];
          this.total = result?.data?.total ?? 0;
          this.isLoading = false;
          this.showAlert = !this.items.length;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    removeItem(item) {
      this.$confirm({ message: "Kayıt sonlandırılıp arşivlenecektir emin misiniz?" }, () => {
        this.isLoading = true;
        this.$axios
          .patch(`/finance/calls/remove-next-call/${item?._id}`)
          .then(() => {
            this.$emitter.$emit("Notification", { variant: "success" });
            this.isLoading = false;
            this.getData();
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", message: err?.data?.message });
            console.log(err);
            this.isLoading = false;
          });
      });
    },
    updateItemShowDetails(item) {
      item.showDetails = !item.showDetails;
    },
    showEndOfForm(item) {
      this.$showAppSidebar("Görüşmeyi Sonlandır", EndOfCallForm, { data: { ...item }, callback: this.getData });
    },
    showContactDetails(item) {
      this.$router.push({ name: "finance-contact-detail", params: { id: item?.contact_id } });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
