<template>
  <section id="dashboard-analytics">
    <b-row>
      <b-col lg="6">
        <customer-requests></customer-requests>
      </b-col>
      <b-col lg="6">
        <todays-calls></todays-calls>
      </b-col>
      <b-col v-if="$can('read', 'pending-confirms')" class="no-wrap" md="2">
        <pending-confirms />
      </b-col>
      <b-col v-if="$can('read', 'pending-web-call-requests')" class="no-wrap" md="2"> <pending-web-call-requests /> </b-col>
    </b-row>
    <b-row> </b-row>
    <div v-if="$can('read', 'dicom-test')">
      <hr />
      <app-button @click="$router.push({ name: 'dicom-test' })" text="Dicom Test" size="md" />
    </div>
  </section>
</template>

<script>
import pendingConfirms from "./pendingConfirms.vue";
import pendingWebCallRequests from "./pendingWebCallRequests.vue";
import TodaysCalls from "./TodaysCalls/TodaysCalls.vue";
import CustomerRequests from "./CustomerRequests/CustomerRequests.vue";

export default {
  components: {
    pendingConfirms,
    pendingWebCallRequests,
    TodaysCalls,
    CustomerRequests,
  },
};
</script>
