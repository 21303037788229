<template>
  <statistic-card
    title="Arama Bekleyenler"
    :count="count"
    :variant="count > 0 ? 'warning' : 'success'"
    icon="PhoneIncomingIcon"
    icon-size="22"
    avatar-size="45"
    route="central"
    hover
  />
</template>

<script>
export default {
  data() {
    return {
      count: 0,
    };
  },
  mounted() {
    this.getCountOfPendinCallRequest();
  },
  methods: {
    getCountOfPendinCallRequest() {
      this.$axios
        .get("/central/web-call-request-list/pending/count")
        .then((result) => {
          this.count = result.data?.count || 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>

<!-- /web-call-request-list/pending/count -->
