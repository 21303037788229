<template>
  <app-overlay :loading="tableLoading">
    <b-card class="border-secondary" body-class="p-50">
      <b-card-header class="p-1">
        <h4 class="m-0"><mdi-icon class="mr-50" size="23" icon="ChatQuestionOutline" />Destek Talepleri</h4>
      </b-card-header>
      <db-searchable-table @row-clicked="toggleDetails" v-model="filters" @changed="getData" :items="items" :fields="fields" no-body-gap no-border :total-rows="total" hover>
        <template #row-details="{ item }">
          <b-card class="border m-50" body-class="p-1">
            <div class="d-flex gap-5">
              <app-button @click="showAnswerRequestForm(item)" text="Yanıtla" />
              <app-button @click="showUpdateStatusForm(item)" text="Durum Güncelle" />
            </div>
            <b-card v-if="item.msg" body-class="p-1" class="border mt-1 mb-50">
              <b-card-header class="p-0">
                <h6 class="m-0">Mesaj:</h6>
              </b-card-header>
              <hr class="my-50" />
              {{ item.msg }}
            </b-card>

            <b-card v-if="item.answerNote" body-class="p-1" class="border m-0">
              <b-card-header class="p-0">
                <h6 class="m-0">Yanıt / Not:</h6>
              </b-card-header>
              <hr class="my-50" />
              {{ item.answerNote }}
            </b-card>
          </b-card>
        </template>
        <template #phone="{ item }">
          <div>
            {{ formatPhone(item.phone) }}
          </div>
          <b-badge :variant="badgeVariants[item.status.key]">
            {{ item.status.label }}
          </b-badge>
        </template>
        <template #icon="{ item }"> <feather-icon size="20" :icon="!item._showDetails ? 'ChevronDownIcon' : 'ChevronUpIcon'" /> </template>
      </db-searchable-table>
    </b-card>
  </app-overlay>
</template>

<script>
import AnswerRequestForm from "./AnswerRequestForm.vue";
import UpdateRequestStatusForm from "./UpdateRequestStatusForm.vue";
export default {
  data() {
    return {
      badgeVariants: {
        pending: "warning",
        contacted: "success",
        answered: "success",
        not_reached: "primary",
      },
      tableLoading: false,
      filters: {},
      items: [],
      total: 0,
      fields: [
        { key: "phone", label: "Telefon" },
        { key: "date", label: "Tarih", formatDateTime: true, sortable: true },
        { key: "supportType", label: "Tür", capitalize: true },
        { key: "icon", label: "", tdClass: "max-10" },
      ],
    };
  },
  methods: {
    getData() {
      this.tableLoading = true;

      this.$axios
        .get("customer-support/requests/paginated-list")
        .then((result) => {
          this.tableLoading = false;
          this.items = result?.data?.data;
          this.total = result?.data?.total;
        })
        .catch((err) => {
          this.tableLoading = false;
          console.log(err);
        });
    },
    toggleDetails(item) {
      if (item["_showDetails"]) item["_showDetails"] = false;
      else this.$set(item, "_showDetails", true);
    },
    formatPhone(phone) {
      return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "0$1 $2 $3 $4");
    },
    showAnswerRequestForm(item) {
      this.$showAppSidebar("Talebi Yanıtla", AnswerRequestForm, { data: { ...item }, callback: this.getData });
    },
    showUpdateStatusForm(item) {
      this.$showAppSidebar("Durum Güncelle", UpdateRequestStatusForm, { data: { ...item }, callback: this.getData });
    },
  },
};
</script>
<style>
.max-10 {
  max-width: 10px;
}
</style>
