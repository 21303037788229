<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="endOfCallForm">
      <b-alert variant="secondary" show>
        <div class="alert-body">
          <feather-icon class="mr-50" icon="InfoIcon" />

          <small> Bu işlemle seçilen görüşme kaydınız arşivlenecek ve yeni görüşme kaydı oluşturulacaktır. </small>
        </div>
      </b-alert>
      <b-card v-if="item" class="border-secondary" body-class="p-1">
        <span class="d-flex justify-content-center text-center">{{ item.company.name }}</span>
      </b-card>
      <callee-auto-suggest-input v-model="callee" :contact_id="item.contact_id" />
      <app-select-input v-model="status" name="Sonuç" :options="statusOptions" label="Sonuç:" placeholder="Sonuç Seçiniz..." select_label="label" rules="required" />
      <app-textarea-input v-model="description" name="Açıklama" label="Açıklama:" placeholder="Açıklama..." />
      <app-date-input v-model="nextCall" name="Sonraki Arama Tarihi" label="Sonraki Arama Tarihi:" placeholder="Tarih Seçiniz..." />

      <app-button @click="endOfCallFormAndCreateAnotherOne" text="Ekle" icon="PlusIcon" size="md" block />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import CalleeAutoSuggestInput from "@/views/Finance/calls/CalleeAutoSuggestInput.vue";
export default {
  components: { ValidationObserver, CalleeAutoSuggestInput },
  data() {
    return {
      status: "",
      description: "",
      nextCall: "",
      callee: "",
      statusOptions: [
        { key: "called", label: "Görüşüldü" },
        { key: "not_reached", label: "Ulaşılamadı" },
        { key: "payment_promise", label: "Ödeme Sözü" },
        { key: "payment_rebuilded", label: "Yapılandırma" },
        { key: "legal_proceeding", label: "Yasal Takip" },
        { key: "other", label: "Diğer" },
      ],
    };
  },
  computed: {
    item() {
      return this.$store.getters?.appSidebarItem?.data;
    },
    callback() {
      return this.$store.getters?.appSidebarItem?.callback;
    },
  },
  methods: {
    setData() {
      return {
        whenCalled: new Date(),
        description: this.description,
        callee: this.callee,
        status: this.status,
        nextCall: this?.nextCall ? new Date(this?.nextCall) : "",
        company: {
          name: this.item?.company?.name,
          taxNo: this?.item?.company?.taxNo,
        },
        contact_id: this?.item?.contact_id,
      };
    },
    endOfCallFormAndCreateAnotherOne() {
      this.$validate(this.$refs.endOfCallForm, () => {
        this.$axios
          .post(`/finance/calls/end-of-call-and-create/${this?.item?._id}`, this.setData(), { loading: "sidebar" })
          .then(() => {
            this.callback && this.callback();
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", variant: "success" });
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", message: err?.data?.message, variant: "danger" });
          });
      });
    },
  },
};
</script>

<style></style>
